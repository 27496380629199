import styled, { css } from 'styled-components';
import {
  TypographyProps,
  TypographyVariant,
} from '@/components/UI/Typography/Typography.types';
import { ellipsisMultiline } from '@/styles/helpers';

function getBodyStyles(variant?: TypographyVariant) {
  switch (variant) {
    case '4': {
      return css`
        font-size: 14px;
      `;
    }
    case '3': {
      return css`
        font-size: 13px;
      `;
    }
    case '2': {
      return css`
        font-size: 15px;
        line-height: 24px;
      `;
    }
    default: {
      return css`
        font-size: 15px;
      `;
    }
  }
}

function getUnderlineStyles(isUnderlined?: boolean) {
  if (isUnderlined) {
    return css`
      text-decoration: underline;
    `;
  }
}

function getLinkStyles(isLink?: boolean) {
  if (isLink) {
    return css`
      font-size: 14px;
      text-decoration: underline;
      color: ${({ theme }) => theme.palette.system.blue};
    `;
  }
}

function getHeaderStyles(variant?: TypographyVariant) {
  switch (variant) {
    case '4': {
      return css`
        font-size: 15px;
        font-weight: 700;
      `;
    }
    case '3': {
      return css`
        font-size: 16px;
        font-weight: 500;
      `;
    }
    case '2': {
      return css`
        font-size: 18px;
        font-weight: 700;
      `;
    }
    case '1': {
      return css`
        font-size: 20px;
        font-weight: 400;
      `;
    }
    case '0': {
      return css`
        font-size: 20px;
        font-weight: 700;
      `;
    }
    default: {
      return css`
        font-size: 20px;
        font-weight: 500;
      `;
    }
  }
}

function getTitleStyles(variant?: TypographyVariant) {
  switch (variant) {
    case '3': {
      return css`
        font-size: 14px;
        font-weight: 400;
      `;
    }
    case '2': {
      return css`
        font-size: 14px;
        font-weight: 500;
      `;
    }
    default: {
      return css`
        font-size: 15px;
        font-weight: 500;
      `;
    }
  }
}

function getCaptionFontSize(variant?: TypographyVariant) {
  switch (variant) {
    case '3': {
      return 11;
    }
    case '2': {
      return 14;
    }
    default: {
      return 12;
    }
  }
}

function getNumbersStyles(variant?: TypographyVariant) {
  switch (variant) {
    case '3': {
      return css`
        font-size: 15px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 3px;
      `;
    }
    case '2': {
      return css`
        font-size: 15px;
        font-weight: 700;
      `;
    }
    default: {
      return css`
        font-size: 24px;
        font-weight: 500;
      `;
    }
  }
}

// TODO: consult with SEO specialist about h1-h6 tags
const Header = styled.h6<TypographyProps>`
  font-style: normal;
  color: ${({ theme }) => theme.palette.primary.labels};

  ${({ $variant }) => getHeaderStyles($variant)}

  ${({ $isUnderlined }) => getUnderlineStyles($isUnderlined)}

  ${({ $isEllipsis }) => $isEllipsis && ellipsisMultiline(1)}
`;

const Title = styled.h6<TypographyProps>`
  font-style: normal;

  ${({ $variant }) => getTitleStyles($variant)}

  ${({ $isUnderlined }) => getUnderlineStyles($isUnderlined)}

  ${({ $isEllipsis }) => $isEllipsis && ellipsisMultiline(1)}

  ${({ $isLink }) => getLinkStyles($isLink)}
`;

const Caption = styled.p<TypographyProps>`
  color: ${({ theme }) => theme.palette.secondary.labels};
  font-size: ${({ $variant }) => `${getCaptionFontSize($variant)}px`};
  font-style: normal;
  font-weight: 400;

  ${({ $isUnderlined }) => getUnderlineStyles($isUnderlined)}

  ${({ $isLink }) => getLinkStyles($isLink)}

  ${({ $isEllipsis }) => $isEllipsis && ellipsisMultiline(1)}
`;

const Body = styled.h6<TypographyProps>`
  font-style: normal;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.primary.labels};
  text-decoration: none;

  ${({ $variant }) => getBodyStyles($variant)}

  ${({ $isUnderlined }) => getUnderlineStyles($isUnderlined)}
    
    ${({ $isEllipsis }) => $isEllipsis && ellipsisMultiline(1)}
`;

const Numbers = styled.p<TypographyProps>`
  ${({ $variant }) => getNumbersStyles($variant)}

  font-style: normal;
  color: ${({ theme }) => theme.palette.primary.labels};
  text-decoration: none;

  ${({ $isUnderlined }) => getUnderlineStyles($isUnderlined)}

  ${({ $isEllipsis }) => $isEllipsis && ellipsisMultiline(1)}
`;

const Tab = styled.p<TypographyProps>`
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;

  ${({ $isUnderlined }) => getUnderlineStyles($isUnderlined)}

  ${({ $isEllipsis }) => $isEllipsis && ellipsisMultiline(1)}
`;

export const Typography = { Header, Title, Caption, Body, Numbers, Tab };
